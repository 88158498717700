body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.content-wrapper {
    flex: 1;
}
.content-page {
  display: flex;
 min-height: 100vh;
 flex-direction: column;
}
.flex-container {
  display: flex;
  align-items: stretch;
  background-color: #f1f1f1;
}
.flex-item {
  -webkit-flex-grow: 1; /* Safari */
  flex-grow: 1;
text-align: center;
padding: 10px;
}
.flex-item a {
  width: 100%;
}
.wysiwyg-editor {
  min-height: 200px
}
form label {
  margin-top: 20px
}
.badge-success {
  background-color: #B1BF41;
}
.btn-warning {
  color: #FFFFFF
}

.actionbutton  button {
  margin-left: 10px;
}
.card {
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  border: none;
}
.card-header {
  background-color: #848c45;
  color: white;
}

.box {
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

@keyframes alert-color {
  from {
    background-color: rgb(255, 94, 0);
  }

  to {
    background-color: rgb(217 105 41);
   
  }
}