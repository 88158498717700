.filter {
    label {
        margin-top: 0px;
    }
    .gemiddelde {
        font-size: 20px;
        display: block;
        font-weight: bold;
        margin-top: 2px;
    }
}
.sensoritem {
    .card-header {
        height: 80px;
    }
}