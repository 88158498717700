

.SandboxRoot.env-bp-820 .timeline-Tweet-text {
  font-size: 20px;
  line-height: 38px;
  font-weight: 300;
}

.loginheight {
  height: 50px;
}

.register-box,
.login-box {
  max-width: 560px;
  margin: 1% auto;
}

.form-control-feedback {
  text-align: left;
}

.btn-wild {
  color: #ffffff;
  background-color: #3ca838;
  border-color: rgba(0, 0, 0, 0.2);
}
.green {
  color: #3aaa35;
}
.blue {
  color: #7fcff5;
}
.darkgreen {
  color: #2f5234;
}
.rood {
  color: #e50040;
}
.roze {
  color: #ea5198;
}
.bruin {
  color: #693c10;
}
.oranje {
  color: #f7a600;
}
.kleur {
  padding-right: 10px;
}

.darkgreenbackground {
  background-color: #2f5234;
  color: #ffffff;
}
.rc-time-picker-input {
  height: 34px;
}
.DateInput_input {
  padding: 3px 1px 1px;
  font-size: 14px;
}
.countbar span {
  margin-left: 5px;
  margin-right: 20px;
  font-weight: bold;
}
html {
  font-size: 14px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 100%;
}
.nav > li > span {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.skin-blue .main-header .navbar nav > li > a {
  background: rgba(0, 0, 0, 0.1);
}

.navbar {
  padding: 0rem 1rem;
}
.img-circle {
  border-radius: 50%;
}
.loginbutton {
  margin-left: 14px;
}

.SingleDatePickerInput__withBorder {
  width: 100%;
}

.SingleDatePicker {
  width: 100%;
}
label {
  margin-bottom: 2px;
}
.DateInput {
  width: 100%;
}

.center-block {
  text-align: center;
}

.submit {
  padding-top: 20px;
}

.btn-primary {
  color: #FFFFFF;
}

.DateInput_1 {
  width: 45% !important;
}
.delbotnleeg {
  margin-top: 45px;
}

.page-item.active .page-link {
  background-color: #414859;
  border-color: #414859;
}

.box.box-primary {
  border-top-color: #414859;
}
.time-label {
  list-style: none;
  font-size: 20px;
}
.loadingsearch {
  padding-top: 55px;
}
.square {
  float: left;
  position: relative;
  width: 150px;
  border: 0px;
  margin: 1.66%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; /* you change this to "contain" if you don't want the images to be cropped */
}
.card-title {
  margin-bottom: 0.2rem;
  padding-top: 1em;
}

.sidebar-collapse #root .main-sidebar .sidebar form {
  display: none;
}
.pointer {
  cursor: pointer;
}
.inschrijving span {
  padding: 10px;
  font-weight: bold;
}

.public-box {
  margin: 1% auto;
  max-width: 800px;
  background-color: #f0f0f0;
  display: grid;
  width: 100%;
}
.public-box-body {
  padding: 5px;
}
.activiteit-box-body {
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
  display: table-cell;
  border-radius: 5px 0px 0px 5px;
  color: #000000;
}
.faciliteit-box-body{
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
  display: table-cell;
  border-radius: 5px 0px 0px 5px;
  color: #000000;
}

.activiteit-time {
  position: relative;
  width: 60px;
  float: left;
  color: #0084c1;
  font-size: 1.1rem;
  font-family: "Verdana", sans-serif;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.activiteit-titel {
  color: #000000;
  font-size: 1.1rem;
}
body {
  font-family: "Source Sans Pro", sans-serif;
}
.login-logo {
  color: #0084c1;
}
.activiteit-datum {
  color: #0184c1;
  font-size: 1.2em;
  margin-top: 20px;
}
.activiteit-arrow {
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  background-color: #FFFFFF;
  border-radius: 0px 5px 5px 0px;
}
.activiteit-link {
  display: block;
  margin-bottom: 20px;

}
.content {
  width: 100%;
  padding: 5px;
}
.back-arrow {
  width: 100%;
  background-color: #FFFFFF;
  padding: 10px;
  display: block;
}
.inschrijf-content > form > .box-body {
  padding-top: 0px;
  margin-bottom: 20px;
  background-color: #FFFFFF;
  color: #0184c1;
}
.submitform {
  margin-top: 20px;
}

.bt-inschrijvingen {
  width: 150px;
  float: right;
  margin-right: 1px;
  position: relative;
  top: -30px;
}
.activiteit-inschrijvingen {
  background-color: #FFFFFF;
}
.weekformsubmit {
  margin-top: 44px;
}
.table-container {
  display: flex;
  flex-flow: column nowrap;
  font-size: .8rem;
  margin: 0.5rem;
  line-height: 1.5;
  border-bottom: 1px solid #d0d0d0;
  flex: 1 1 auto;
  overflow-x: auto;
  white-space: nowrap;
}

.th {
  display: none;
  font-weight: 700;
  background-color: #f2f2f2;
}

.flex-row > .td {
  white-space: normal;
  justify-content: center;
}

.flex-row {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.flex-row:nth-of-type(even) {
  background-color: #f2f2f2;
}

.flex-row:nth-of-type(odd) {
  background-color: #ffffff;
}

.flex-cell {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5em;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0px;
  white-space: nowrap;
  border-bottom: 1px solid #d0d0d0;
  min-width: 100px;
}

.react-tagsinput-input {
  width: 150px;
}
.weekform {
  margin: 10px;
  background-color: #ecf0f5;
  padding: 10px;
}
.langlogo {
  height: 10px;
    display: contents;
}
.facheadline {
  height: 60px;
}
.maincontent {
  margin-bottom: 110px
}
.gastinfo {
  margin-bottom: 20px;
    background-color: white;
    padding: 10px;

}
.login-logo {
  font-size: 25px;
}
.mobileheader {
  font-size: 25px;
}
.mobileheader .btn {
  padding: 0px;

}
@media (max-width: 330px) {
  
  h3 {
    font-size: 20px;
  }

}
.inschrijform {
  border: #04a659 solid;
  margin: 40px;
}
.content-wrapper, .main-footer {
  z-index: unset;
}
.barback {
  background-color: #ecf0f5;
  padding-top: 12px;
}

.react-bootstrap-table table {
  table-layout: fixed;
}
.bedrijfs-link {
  color: #0084c1  !important;
}
.input-range-style {
  padding: 20px
}
.submit-bt {
  margin-top: 20px;
}

.wizzard .actief {
  background-color:#848c45;
}

.wizzard .actief a {
  color:#FFFFFF;
}

.uploadfiles {
  list-style-type: none;
  padding: 2px;
}
.uploadfiles li {
  padding: 2px;
  height: 30px;
  width: 40%;
  border-bottom: #efefef solid 2px;
}
.uploadfiles span {
float: right;
}

.fullheight {
  height:calc(100vh - 130px)

}
.item_eigenschappen {
  list-style-type: none;
  padding: 0px;
}
.item_eigenschappen li {
display:inline-block;
padding: 5px;
margin: 2px;
}

.item_bestanden {
  list-style-type: none;
  padding: 0px;
}

.item_bestanden li {
  display:block;
  padding: 5px;
  margin: 2px;
  }
  .login-logo {
    position: relative;
  }
  .landen {
    position:absolute;
    right: 5px;
  }
  .treeview-menu {
    transform: scaleY(0);    
  transform-origin: top;

  }
  .menu-open {
    display: block !important;
    transform: scaleY(1);

  }
.cursurhand {
  cursor: pointer;
}
.loadingmessages {
  position: absolute;
  z-index: 100;
  right: 10px;
  bottom: 0px;

}
